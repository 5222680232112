<template>
  <v-row class="pa-0 ma-0 font-family-raleway-medium pt-6">
    <v-col lg="12" md="12" xl="12">
      <p>{{ $t("profile_page.title") }}</p>
      <v-card class="profile-settings-card">
        <h2 class="text-uppercase">{{ $t("profile_page.profile_title") }}</h2>
        <div class="d-flex mt-8">
          <h3 class="font-family-raleway-bold">
            {{ $t("profile_page.personal_info") }}
          </h3>
          <v-btn
            class="ml-2 box-shadow-none text-capitalize"
            style="background-color: #ffffff"
            small
            v-if="!isEditProfileInfos"
            @click="isEditProfileInfos = true"
          >
            <v-icon small class="mr-1">mdi-pencil-outline</v-icon>
            <span class="font-family-raleway-medium">{{
              $t("edit_button")
            }}</span>
          </v-btn>
        </div>
        <p class="mt-3 font-family-raleway-medium">
          {{ $t("profile_page.change_description") }}
        </p>
        <div class="d-flex fld">
          <div class="avatar" style="width: 180px; height: 180px">
            <v-avatar width="180" height="180">
              <img
                v-if="uploadedFile"
                :src="uploadedFile"
                style="object-fit: cover"
              />
              <div v-else>
                <div class="noImageStyle">{{ getFirstLetterOfName }}</div>
              </div>
            </v-avatar>
            <img
              :src="cameraIcon"
              class="icon-camera"
              v-if="isEditProfileInfos"
              @click="$refs.uploader.click()"
            />
            <input
              ref="uploader"
              class="d-none"
              type="file"
              @change="onFileChanged"
            />
          </div>
          <v-form ref="form" v-model="form" class="d-flex fld" :style="{ width: 'calc(100% - 180px)' }" style="min-width: 300px">
              <div class="ml-7 mt-8 ml-zero" style="width: 100%; max-width:322px">
              <h5 class="font-family-raleway-medium">
                {{ $t("profile_page.full_name_input") }}
              </h5>
              <v-text-field
                v-model="getUser.fullName"
                :readonly="!isEditProfileInfos"
                :outlined="isEditProfileInfos"
                class="mt-2 profile-settings-textfield user-info-textfield font-family-raleway-medium"
                :class="isEditProfileInfos ? 'bg-white' : 'bg-textField'"

                :hide-details="!isEditProfileInfos"
                :rules="rule"
                height="45"
                required
                rounded
                dense
              ></v-text-field>
            </div>
              <div class="ml-7 mt-8 ml-zero" style="width: 100%; max-width:322px">
              <h5 class="font-family-raleway-medium">
                {{ $t("email_input") }}
              </h5>
              <v-text-field
                v-model="getUser.email"
                :readonly="!isEditProfileInfos"
                :outlined="isEditProfileInfos"
                class="mt-2 profile-settings-textfield user-info-textfield font-family-raleway-medium"
                :class="isEditProfileInfos ? 'bg-white' : 'bg-textField'"

                :hide-details="!isEditProfileInfos"
                :rules="rule"
                height="45"
                required
                rounded
                dense
              ></v-text-field>
            </div>


          </v-form>
        </div>

        <div class="float-right mb-10" v-if="isEditProfileInfos">
          <v-btn
            class="font-family-raleway-medium box-shadow-none mr-10 text-none"
            style="background-color: #fff !important"
            @click="handleCancelEditingMode"
          >
            <a class="color-black" href="#">{{ $t("cancel") }}</a>
          </v-btn>

          <v-btn
            class="font-family-raleway-medium bg-primary color-white border-16"
            height="45"
            width="220"
            @click="onSaveChanges"
            :disabled="!form"
            :loading="loadingUpdateUserInfos"
          >
            <strong>{{ $t("profile_page.save_changes_button") }}</strong>
          </v-btn>
        </div>

        <!--password changes-->
        <div class="mt-10"  style="clear: right;">
          <h3 class="font-family-raleway-bold">{{ $t("password") }}</h3>
          <p class="mt-3 font-family-raleway-medium" style="color: black">
            {{ $t("profile_page.password_description") }}
          </p>
          <div
            class="mt-4 d-flex justify-space-between align-center"
            v-if="isChangePassword" style="flex-wrap: wrap"
          >
            <v-form ref="formPassword" v-model="formPassword" class="d-flex" style="flex-wrap: wrap">
              <div>
                <h5 class="font-family-raleway-medium">
                  {{ $t("profile_page.old_password_input") }}
                </h5>
                <v-text-field
                  v-model="passwordInfos.oldPassword"
                  class="mt-2 profile-settings-textfield font-family-raleway-medium"
                  style="width: 220px"
                  type="password"
                  :rules="rule"
                  height="45"
                  outlined
                  required
                  rounded
                  dense
                />
              </div>
              <div class="ml-3 ml-zero">
                <h5 class="font-family-raleway-medium">
                  {{ $t("profile_page.new_password_input") }}
                </h5>
                <v-text-field
                  v-model="passwordInfos.newPassword"
                  class="mt-2 profile-settings-textfield font-family-raleway-medium"
                  style="width: 220px"
                  type="password"
                  :rules="rule"
                  height="45"
                  outlined
                  required
                  rounded
                  dense
                />
              </div>
              <div class="ml-3 ml-zero">
                <h5 class="font-family-raleway-medium">
                  {{ $t("profile_page.confirm_password_input") }}
                </h5>
                <v-text-field
                  v-model="passwordInfos.confirmPassword"
                  class="mt-2 profile-settings-textfield font-family-raleway-medium"
                  style="width: 220px"
                  type="password"
                  :rules="ruleConfirm"
                  height="45"
                  outlined
                  required
                  rounded
                  dense
                />
              </div>
            </v-form>
            <div>
              <v-btn
                class="font-family-raleway-medium box-shadow-none mr-10 text-none"
                style="background-color: #fff !important"
                @click="handleCancelEditingModePassword"
              >
                <a class="color-black" href="#">{{ $t("cancel") }}</a>
              </v-btn>
              <v-btn
                class="font-family-raleway-medium bg-primary color-white border-16"
                height="45"
                width="220"
                @click="onSaveChangesPassword"
                :disabled="!formPassword"
                :loading="loadingUpdateUserPassword"
              >
                <strong>{{$t("profile_page.update_button")}}</strong>
              </v-btn>
            </div>
          </div>

          <div class="mt-4" v-else>
            <v-btn
              class="font-family-raleway-medium bg-primary color-white border-16"
              height="50"
              width="220"
              @click="isChangePassword = true"
            >
              <strong>{{ $t("profile_page.change_password_button") }}</strong>
            </v-btn>
          </div>
        </div>
        <v-divider class="my-10" />
        <!--Billing information-->
        <BillingInformation />
      </v-card>
    </v-col>
    <snackbar :text="snackbarText" :vModel="isUploadingImage" />
  </v-row>
</template>
<script>
import cameraIcon from "../../assets/img/editAvatar.svg";
import { authService } from "../../services/auth";
import downloadIcon from "../../assets/icon/download.svg";
import { baseUrl } from "../../main";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import { uploadFile } from "@/services/file";

export default {
  name: "ProfileSettings",
  components: {
    Snackbar: () => import("@/components/snackbar"),
    BillingInformation: () => import("../../components/Account/user-settings/BillingInformation")
  },
  data() {
    return {
      cameraIcon,
      isEditProfileInfos: false,
      loadingUpdateUserInfos: false,
      loadingUpdateUserPassword: false,
      isChangePassword: false,
      formPassword: false,
      isUploadingImage: false,
      form: false,
      uploadedFile: "",
      downloadIcon,
      profileInfos: {
        userAvatar: "",
        fullName: "",
        email: "",
      },
      passwordInfos: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      rule: [
        (v) => !!v || "Required",
        (v) => v?.length >= 6 || "At least 6 characters",
      ],
      ruleConfirm: [
        (v) => !!v || "Required",
        (v) =>
          v === this.passwordInfos.newPassword || "Passwords did not match",
      ],
      snackbarText: "",
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
    getFirstLetterOfName() {
      return this.getUser?.fullName?.charAt(0).toUpperCase();
    },
  },
  async created() {
    await this.getUserInfos();
  },
  methods: {
    ...mapActions(["setUser"]),
    getAvatarPath(url) {
      return baseUrl + "/" + url;
    },
    async getUserInfos() {
      const response = await authService.getUserInfo();
      this.profileInfos = response?.data;
      const { userAvatar } = response.data;
      if (userAvatar) {
        this.uploadedFile = this.getAvatarPath(userAvatar);
      }
      this.setUser(response?.data);
    },
    async onFileChanged(e) {
      const uploadingFile = e.target.files[0];
      this.uploadedFile = URL.createObjectURL(uploadingFile);
      this.uploadingFile = uploadingFile;
    },
    async onSaveChanges() {
      this.loadingUpdateUserInfos = true;
      try {
        const response = await authService.updateUserInfos({
          id: this.getUser.id,
          fullName: this.getUser.fullName,
          email: this.getUser.email,
        });
        const token = response?.data?.token;
        localStorage.setItem("token", token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        const formData = new FormData();
        formData.append("image", this.uploadingFile);
        this.isUploadingImage = true;
        this.snackbarText = "Uploading avatar...";
        try {
          await uploadFile.upload(formData);
        } catch (e) {
          console.log(e);
        }
        this.isUploadingImage = false;
        this.$notify({
          group: "foo",
          title: this.$t("successfully_updated"),
          type: "success",
          timestamp: 2000,
        });
        await this.getUserInfos();
      } catch (err) {
        const errorText = err.response?.statusText;
        this.$notify({
          group: "foo",
          title: errorText || this.$t("something_wrong"),
          type: "error",
          timestamp: 1000,
        });
      }
      this.loadingUpdateUserInfos = false;
      this.isEditProfileInfos = false;
    },
    async handleCancelEditingMode() {
      await this.getUserInfos();
      this.isEditProfileInfos = false;
    },
    async onSaveChangesPassword() {
      this.loadingUpdateUserPassword = true;
      try {
        await authService.updateUserPassword(this.passwordInfos);
        this.$notify({
          group: "foo",
          title: this.$t("successfully_updated"),
          type: "success",
          timestamp: 2000,
        });
      } catch (err) {
        console.log("err", err?.response);
      }
      this.loadingUpdateUserPassword = false;
    },
    handleCancelEditingModePassword() {
      this.isChangePassword = false;
    },
  },
};
</script>

<style scoped lang="scss">
.profile-settings-card {
  padding: 40px 40px 20px 40px;
  box-shadow: none !important;
  border-radius: 12px;
  @media screen and (max-width: 600px) {
    padding: 20px 15px;
  }
  @media screen and (max-width: 450px) {
    padding: 20px 10px;
  }
}

.avatar {
  position: relative !important;
}
.icon-camera {
  position: absolute;
  bottom: 0 !important;
  right: 0 !important;
  cursor: pointer !important;
}
.user-info-textfield{
  width: 100%!important;
}
.profile-settings-textfield {
  border-radius: 10px !important;
  width: 300px;
}

.bg-textField {
  background-color: rgba(222, 219, 227, 0.25) !important;
}

.bg-white {
  background-color: #ffffff !important;
}
.noImageStyle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: #150da8;
  font-size: 55px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  line-height: 150px;
  margin: 20px 0;
}
.fld{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

@media screen and (max-width: 549px) {
  .ml-zero{
    margin-left: 0!important;
    max-width: 100%!important;
  }
  .fld{
    width: 100%!important;
  }
  .fld>form{
    min-width: 0!important;
  }
}
</style>
